import $ from 'jquery';


function processScan(code) {
  console.log('processing scan');
  var form = $('form#car_loan_form');
  if (form.length) {
    console.log('setting value');
    form.find('input#token').val(code);
    form.submit();
  }
}


var SCANNER_TIMEOUT = 150;

var cardReading = false;
var cardReadingTime = new Date();
var cardBuffer = "";

function detectBarcodeScan(e)
{
  var d = new Date();
  
  // console.log("Buffer before: " + cardBuffer);

  if ((d.getTime() - cardReadingTime.getTime()) >= SCANNER_TIMEOUT)
  {
    cardBuffer = "";
    cardReadingTime = d;
  }

  if (e.which == 13)
  {
    if (cardBuffer.length > 1)
    {
      console.log("Reporting scan. Code: " + cardBuffer);
      processScan(cardBuffer);
      cardBuffer = "";
      cardReading = false;
      return false;
    }
    else
    {
      cardBuffer = "";
      cardReadingTime == d;
    }
  }
  else
  {
    // console.log("Processing regular key press for card swipe. Code: " + e.which.toString());
    if ((d.getTime() - cardReadingTime.getTime()) < SCANNER_TIMEOUT)
    {
      if ((e.which >= 48 && e.which <= 57))
      {
        cardBuffer += String.fromCharCode( e.which );
      }
      else 
      {
        if (e.which != 367 && e.which != 95 && e.which != 59 && e.which != 63)
        {
          switch (e.which)
          {
            case 201 :
              cardBuffer += '0';
              break;
            case 233 :
              cardBuffer += '0';
              break;
            case 43 :
              cardBuffer += '1';
              break;
            case 282 :
              cardBuffer += '2';
              break;
            case 283 :
              cardBuffer += '2';
              break;
            case 352 :
              cardBuffer += '3';
              break;
            case 353 :
              cardBuffer += '3';
              break;
            case 268 :
              cardBuffer += '4';
              break;
            case 269 :
              cardBuffer += '4';
              break;
            case 344 :
              cardBuffer += '5';
              break;
            case 345 :
              cardBuffer += '5';
              break;
            case 381 :
              cardBuffer += '6';
              break;
            case 382 :
              cardBuffer += '6';
              break;
            case 221 :
              cardBuffer += '7';
              break;
            case 253 :
              cardBuffer += '7';
              break;
            case 193 :
              cardBuffer += '8';
              break;
            case 225 :
              cardBuffer += '8';
              break;
            case 205 :
              cardBuffer += '9';
              break;
            case 237 :
              cardBuffer += '9';
              break;
            default :
              cardBuffer += String.fromCharCode( e.which );
          }
        }
      }
      cardReadingTime = d;
      // console.log("Buffer after: " + cardBuffer);
      return false;
    }
    else
    {
      cardBuffer = "";
      // console.log("Buffer cleared");
      return true;
    }
  }
}

function refreshList() {
  $('.periodic_refresh').load($('.periodic_refresh').data('url'), function() {
    $(window).scrollTop(0);
    setTimeout(refreshList, parseInt($('.periodic_refresh').data('period')));
  });

}

function initMap() {
  const location_element = document.querySelector('#location');

  if (location_element) {
    var current_location = {lat: parseFloat(location_element.dataset.latitude), lng: parseFloat(location_element.dataset.longitude)};

    if (current_location["lat"] != 0 || current_location["lng"] != 0)
    {
      var map = new google.maps.Map(document.getElementById('map'),
        {
          center: current_location,
          zoom: 14,
          mapTypeControl: false,
          streetViewControl: false
        }
      );

      var current_marker = new google.maps.Marker({
        position: current_location,
        map: map,
        title: 'Current position'
      });
    }

    const multi_map_element = $('#multi-map');
    if (multi_map_element.length) {
      // var center_location = {lat: 48.0453651, lng: 16.704163};

      var map2 = new google.maps.Map(document.getElementById('multi-map'),
      {
        center: current_location,
        zoom: 14,
        mapTypeControl: true,
        streetViewControl: false
      });

      var bounds = new google.maps.LatLngBounds();

      var locations = multi_map_element.data('locations');
      for (var i = 0; i < locations.length; i++) {
        const marker = new google.maps.Marker({
          position: locations[i],
          map: map2,
          title: locations[i]["text"]
        });

        bounds.extend(new google.maps.LatLng(locations[i]["lat"], locations[i]["lng"]));
      }

      map2.fitBounds(bounds);
    }
  }
}

$(document).ready(function() {
  if ($('.process_barcodes').length) {
    document.addEventListener("keypress", detectBarcodeScan);
  }

  if ($('.periodic_refresh').length) {
    refreshList();
  }

  if ($('#location').length) {
    initMap();
  }
  
  $('form#new_registration_form').submit(function() {
    var errors = 0;
    
    if ($('input#participant_last_name').length > 0) {
      if ($("input#participant_last_name").val().length < 2) {
        $("input#participant_last_name").parent().addClass('field_with_errors');
        errors++;
      } else {
        $("input#participant_last_name").parent().removeClass('field_with_errors');
      }
      
      if ($("input#participant_first_name").val().length < 2) {
        $("input#participant_first_name").parent().addClass('field_with_errors');
        errors++;
      } else {
        $("input#participant_first_name").parent().removeClass('field_with_errors');
      }
      
      if ($("input#participant_group").val().length < 2) {
        $("input#participant_group").parent().addClass('field_with_errors');
        errors++;
      } else {
        $("input#participant_group").parent().removeClass('field_with_errors');
      }
      
      if ($("input#participant_email").val().length < 2) {
        $("input#participant_email").parent().addClass('field_with_errors');
        errors++;
      } else {
        $("input#participant_email").parent().removeClass('field_with_errors');
      }
      
      if (!$("input#press_conference").prop('checked')) {
        $("input#press_conference").parent().addClass('field_with_errors');
        errors++;
      } else {
        $("input#press_conference").parent().removeClass('field_with_errors');
      } 
    }
    
    if (errors > 0) {
      $("html, body").animate({ scrollTop: $('form#new_registration_form').offset().top }, "slow");
      return false; 
    } else {
      var selectedSlotIds = [];
      $('.drive-reservator .slot.selected').each(function() {
        selectedSlotIds.push($(this).data('slot-id').toString());
      });
      $('#selected_slot_ids').val(selectedSlotIds.join(','));
    }
  });
  
  $('.drive-reservator .slot').click(function() {
    console.log('reserving slot');
    
    if (!$(this).hasClass('reserved')) {
      if ($(this).hasClass('selected')) {
        // unselecting slot
        $(this).find('.status').text('VOLNO');
        $(this).removeClass('selected');
      } else {
        // check for other slots in same time
        if ($('.drive-reservator .slot.selected.' + $(this).data('slot-serial')).length > 0) {
          alert("Na tento čas již máte zvolen jiný model.");
          return;
        } 
        
        if ($(this).closest('.car-slots').find('.slot.selected').length == 0) {
          // fresh slot selection check total count
          var maxSlots = parseInt($('.drive-reservator').data('max-slots'));
          var selectedSlotCount = $('.drive-reservator .slot.selected').length;
          
          if (selectedSlotCount >= maxSlots) {
            alert("Máte již bohužel zvolen maximální počet jízd (" + maxSlots.toString() + ").");
            return;
          }
        }
        
        $(this).closest('.car-slots').find('.slot.selected').each(function() {
          $(this).find('.status').text('VOLNO');
          $(this).removeClass('selected');
        });
        
        $(this).addClass('selected');
        $(this).find('.status').text("ZVOLENO");
      }
    }
  });
  
  $('#participant_test_drives').click(function() {
    if ($("#participant_test_drives").prop('checked')) {
      $('.drive-reservator').slideDown();
    } else {
      $('.drive-reservator').slideUp();
    }
  });

  $('body.car_list.tomorrow table .status_badge').click(function() {
    var status = $(this).data("status");
    switch (status) {
      case 0:
        $(this).css('background-color', 'green');
        $(this).data('status', 1);
        break;
      case 1:
        $(this).css('background-color', 'firebrick');
        $(this).data('status', 2);
        break;
      default:
        $(this).css('background-color', 'silver');
        $(this).data('status', 0);
    }
  });
});