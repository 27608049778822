// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap/dist/js/bootstrap'
require('css/application.scss')

require('reserver')

const images = require.context('../images', true)

function resizeCarList() {
  if ($( window ).width() < 1900) {
    $('.top-banner').css('height', '84px');
    $('.day-indicator').css('font-size','10px');
  }
}

$(document).ready(function() {
  $('.hide-top-banner').click(function() {
    $('.top-banner').hide();
    $(this).hide();
    $('.day-indicator').css('top','0px');
    return false;
  });

  if ($('body.car_list').length) {
    $(window).resize(function() {
      // resizeCarList()
    });

    // resizeCarList();
  }

  if ($('#manual-loan').length) {
    $('#add_loan_by_hand').click(function() {
      $(this).hide();
      $('#manual-loan-form').slideDown();
    });
  }

});